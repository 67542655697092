import type { ContextData } from '@wirechunk/schemas/context-data/context-data';
import type { BooleanExpression } from '@wirechunk/schemas/expressions/boolean-expression';
import type { Delta } from '../../api.ts';
import type { CssProperties } from '../css-properties.ts';

export enum ComponentType {
  AgreementsGuard = 'AgreementsGuard',
  AppPageTiles = 'AppPageTiles',
  OrganizationBilling = 'OrganizationBilling',
  // TODO: Remove BackButton.
  BackButton = 'BackButton',
  // TODO: Replace with WidthContainer.
  BodyContainer = 'BodyContainer',
  // TODO: Rename to CheckboxInput.
  BooleanInput = 'BooleanInput',
  Box = 'Box',
  Button = 'Button',
  CalendlyEmbed = 'CalendlyEmbed',
  CollapsiblePanel = 'CollapsiblePanel',
  CompensationCalculator = 'CompensationCalculator',
  CompletableVideoInput = 'CompletableVideoInput',
  ConditionalLogic = 'ConditionalLogic',
  Course = 'Course',
  CourseSubSection = 'CourseSubSection',
  CurrentYear = 'CurrentYear',
  CustomizeOrganizationSite = 'CustomizeOrganizationSite',
  Dashboard = 'Dashboard',
  DataInputTable = 'DataInputTable',
  DateInput = 'DateInput',
  Design = 'Design',
  Document = 'Document',
  DropdownInput = 'DropdownInput',
  Form = 'Form',
  FormConfirmationMessage = 'FormConfirmationMessage',
  FormStep = 'FormStep',
  HelpTicketsPortal = 'HelpTicketsPortal',
  HTML = 'HTML',
  Icon = 'Icon',
  InputValidationErrorMessage = 'InputValidationErrorMessage',
  Image = 'Image',
  Link = 'Link',
  Map = 'Map',
  MarketingCalculator = 'MarketingCalculator',
  MoveUserPlan = 'MoveUserPlan',
  MultiOrgView = 'MultiOrgView',
  MultipleChoiceInput = 'MultipleChoiceInput',
  MultiSelectInput = 'MultiSelectInput',
  NumberInput = 'NumberInput',
  OrderPage = 'OrderPage',
  // TODO: Deprecate and remove all these Organization property components.
  OrganizationAgencyAddressLine1 = 'OrganizationAgencyAddressLine1',
  OrganizationAgencyAddressLine2 = 'OrganizationAgencyAddressLine2',
  OrganizationAgencyAddressCity = 'OrganizationAgencyAddressCity',
  OrganizationAgencyAddressState = 'OrganizationAgencyAddressState',
  OrganizationAgencyAddressZip = 'OrganizationAgencyAddressZip',
  OrganizationAgencyPrimaryPhone = 'OrganizationAgencyPrimaryPhone',
  OrganizationAgentFirstName = 'OrganizationAgentFirstName',
  OrganizationAgentFullName = 'OrganizationAgentFullName',
  OrganizationAgentLastName = 'OrganizationAgentLastName',
  // TODO: Replace with SiteLogo.
  OrganizationLogo = 'OrganizationLogo',
  // TODO: Replace with SiteName.
  OrganizationName = 'OrganizationName',
  OrganizationSiteForms = 'OrganizationSiteForms',
  OrganizationSiteLeads = 'OrganizationSiteLeads',
  OrganizationSiteReviews = 'OrganizationSiteReviews',
  OrganizationTeam = 'OrganizationTeam',
  // TODO: Replace with SiteTermsOfUse.
  OrganizationTermsOfUse = 'OrganizationTermsOfUse',
  // TODO: Rename this to SitePrivacyPolicy.
  OrganizationPrivacyPolicy = 'OrganizationPrivacyPolicy',
  Padding = 'Padding',
  // Used inside a layout.
  Page = 'Page',
  PhoneLink = 'PhoneLink',
  PlainText = 'PlainText',
  Popover = 'Popover',
  ProvideProps = 'ProvideProps',
  RadioGroupInput = 'RadioGroupInput',
  RadioGroupInputButton = 'RadioGroupInputButton',
  RequestPasswordReset = 'RequestPasswordReset',
  ResetPassword = 'ResetPassword',
  // TODO: Replace with Document.
  Resource = 'Resource',
  Review = 'Review',
  ScoreMyCall = 'ScoreMyCall',
  SetUpSiteGuard = 'SetUpSiteGuard',
  SignInGuard = 'SignInGuard',
  SiteLandingPages = 'SiteLandingPages',
  SiteLogo = 'SiteLogo',
  SiteName = 'SiteName',
  SitePrivacyPolicy = 'SitePrivacyPolicy',
  SiteTermsOfUse = 'SiteTermsOfUse',
  Stage = 'Stage',
  StageBody = 'StageBody',
  StageFileInput = 'StageFileInput',
  StageName = 'StageName',
  // TODO: Rename to ProductItemGuard.
  SubscriptionTagGuard = 'SubscriptionTagGuard',
  Text = 'Text',
  TextInput = 'TextInput',
  TextareaInput = 'TextareaInput',
  Typeform = 'Typeform',
  UserProfile = 'UserProfile',
  VerifiedEmailAddressGuard = 'VerifiedEmailAddressGuard',
  VerifyEmailAddress = 'VerifyEmailAddress',
  Video = 'Video',
  ViewPlanStagesButton = 'ViewPlanStagesButton',
  WidthContainer = 'WidthContainer',
}

export type ComponentBase<T> = {
  type: T;
  id: string;
};

export type ComponentSpec<T extends ComponentType> = ComponentBase<T> &
  Children &
  ClassName &
  ConditionalStyles &
  DataInputTableColumnOptions &
  DefaultValue &
  ElementId &
  Label &
  MaxLength &
  Name &
  CustomProps &
  Required &
  Slot &
  Styles &
  Value;

export type Children = {
  children?: Component[] | null;
};

export type ClassName = {
  className?: string | null;
};

export enum DataSource {
  Direct = 'Direct',
  Prop = 'Prop',
}

type DataSourceSpecDirect<DirectType> = DirectType & {
  type: DataSource.Direct;
};

type DataSourceSpecProp = {
  type: DataSource.Prop;
  // The prop name.
  name: string;
};

export type DataSourceSpec<DirectType> = DataSourceSpecDirect<DirectType> | DataSourceSpecProp;

export enum DataInputTableColumnPlacement {
  Column = 'Column',
  ExtrasPanel = 'ExtrasPanel',
}

export type DataInputTableColumnOptions = {
  dataInputTableColumnOptions?: {
    // className is used as the className for the Column in the table.
    className?: string | null;
    header?: string | null;
    width?: string | null;
  } | null;
};

export enum ConditionalLogicSlotKey {
  True = 'True',
  False = 'False',
}

export type ElementId = {
  elementId?: string | null;
};

export type Label = {
  label?: string | null;
  labelStyles?: Styles | null;
};

export type MaxLength = {
  maxLength?: number | null;
};

export type Name = {
  name?: string | null;
};

export type CustomProps = {
  customProps?: ContextData | null;
};

export type Required = {
  required?: boolean | null;
};

export enum SubscriptionTagGuardSlotKey {
  AccessAllowed = 'AccessAllowed',
  AccessDenied = 'AccessDenied',
}

export type SlotKey = ConditionalLogicSlotKey | SubscriptionTagGuardSlotKey;

export type Slot = {
  slot?: SlotKey | null;
};

export type BooleanValueSource = DataSourceSpec<{
  value?: boolean | null;
}>;

// A string value can be used for a TextInput, a TextareaInput, a DropdownInput, or a RadioGroupInput.
export type StringValueSource = DataSourceSpec<{
  value?: string | null;
}>;

export type NumberValueSource = DataSourceSpec<{
  value?: number | null;
}>;

export type DefaultValue = {
  defaultValue?: BooleanValueSource | NumberValueSource | StringValueSource | null;
};

export type Value = {
  value?: BooleanValueSource | NumberValueSource | StringValueSource | null;
};

export type BaseStyles = {
  styles?: CssProperties;
  smStyles?: CssProperties;
  mdStyles?: CssProperties;
  lgStyles?: CssProperties;
  xlStyles?: CssProperties;
  xxlStyles?: CssProperties;
};

export type Styles = BaseStyles & {
  hoverStyles?: BaseStyles;
  focusVisibleStyles?: BaseStyles;
};

export type ConditionAndStyles = {
  // A unique string for the elements in the array where this object is found.
  key: string;
  condition: BooleanExpression;
  styles: Styles;
};

export type ConditionalStyles = {
  conditionalStyles?: ConditionAndStyles[] | null;
};

// A Component is either a basic component (meaning it doesn't have any configuration specific to its type) or
// any other type of component not listed in the BasicComponentType enum.
export type Components = {
  [ComponentType.AppPageTiles]: AppPageTilesComponent;
  [ComponentType.AgreementsGuard]: ComponentSpec<ComponentType.AgreementsGuard>;
  [ComponentType.BackButton]: ComponentSpec<ComponentType.BackButton>;
  [ComponentType.BodyContainer]: BodyContainerComponent;
  [ComponentType.BooleanInput]: BooleanInputComponent;
  [ComponentType.Box]: BoxComponent;
  [ComponentType.Button]: ButtonComponent;
  [ComponentType.CalendlyEmbed]: CalendlyEmbedComponent;
  [ComponentType.CompensationCalculator]: CompensationCalculatorComponent;
  [ComponentType.ConditionalLogic]: ConditionalLogicComponent;
  [ComponentType.Course]: CourseComponent;
  [ComponentType.CourseSubSection]: CourseSubSectionComponent;
  [ComponentType.CurrentYear]: CurrentYearComponent;
  [ComponentType.CustomizeOrganizationSite]: CustomizeOrganizationSiteComponent;
  [ComponentType.Dashboard]: ComponentSpec<ComponentType.Dashboard>;
  [ComponentType.CollapsiblePanel]: CollapsiblePanelComponent;
  [ComponentType.CompletableVideoInput]: CompletableVideoInputComponent;
  [ComponentType.DataInputTable]: DataInputTableComponent;
  [ComponentType.DateInput]: DateInputComponent;
  [ComponentType.Design]: DesignComponent;
  [ComponentType.Document]: DocumentComponent;
  [ComponentType.DropdownInput]: DropdownInputComponent;
  [ComponentType.Form]: FormComponent;
  [ComponentType.FormConfirmationMessage]: FormConfirmationMessageComponent;
  [ComponentType.FormStep]: FormStepComponent;
  [ComponentType.HelpTicketsPortal]: ComponentSpec<ComponentType.HelpTicketsPortal>;
  [ComponentType.HTML]: HTMLComponent;
  [ComponentType.Icon]: IconComponent;
  [ComponentType.InputValidationErrorMessage]: ComponentSpec<ComponentType.InputValidationErrorMessage>;
  [ComponentType.Image]: ImageComponent;
  [ComponentType.Link]: LinkComponent;
  [ComponentType.Map]: MapComponent;
  [ComponentType.MarketingCalculator]: MarketingCalculatorComponent;
  [ComponentType.MoveUserPlan]: MoveUserPlanComponent;
  [ComponentType.MultiOrgView]: MultiOrgViewComponent;
  [ComponentType.MultipleChoiceInput]: MultipleChoiceInputComponent;
  [ComponentType.MultiSelectInput]: MultiSelectInputComponent;
  [ComponentType.NumberInput]: NumberInputComponent;
  [ComponentType.OrderPage]: OrderPageComponent;
  [ComponentType.OrganizationAgencyAddressState]: OrganizationAgencyAddressStateComponent;
  [ComponentType.OrganizationAgencyPrimaryPhone]: OrganizationAgencyPrimaryPhoneComponent;
  [ComponentType.OrganizationAgencyAddressCity]: OrganizationAgencyAddressCityComponent;
  [ComponentType.OrganizationAgencyAddressLine1]: OrganizationAgencyAddressLine1Component;
  [ComponentType.OrganizationAgencyAddressLine2]: OrganizationAgencyAddressLine2Component;
  [ComponentType.OrganizationAgencyAddressZip]: OrganizationAgencyAddressZipComponent;
  [ComponentType.OrganizationAgentFirstName]: OrganizationAgentFirstNameComponent;
  [ComponentType.OrganizationAgentFullName]: OrganizationAgentFullNameComponent;
  [ComponentType.OrganizationAgentLastName]: OrganizationAgentLastNameComponent;
  [ComponentType.OrganizationBilling]: ComponentSpec<ComponentType.OrganizationBilling>;
  [ComponentType.OrganizationName]: ComponentSpec<ComponentType.OrganizationName>;
  [ComponentType.OrganizationSiteForms]: ComponentSpec<ComponentType.OrganizationSiteForms>;
  [ComponentType.OrganizationSiteLeads]: ComponentSpec<ComponentType.OrganizationSiteLeads>;
  [ComponentType.OrganizationSiteReviews]: ComponentSpec<ComponentType.OrganizationSiteReviews>;
  [ComponentType.OrganizationTeam]: OrgTeamComponent;
  [ComponentType.OrganizationTermsOfUse]: ComponentSpec<ComponentType.OrganizationTermsOfUse>;
  [ComponentType.OrganizationPrivacyPolicy]: ComponentSpec<ComponentType.OrganizationPrivacyPolicy>;
  [ComponentType.OrganizationLogo]: OrganizationLogoComponent;
  [ComponentType.Padding]: PaddingComponent;
  [ComponentType.Page]: ComponentSpec<ComponentType.Page>;
  [ComponentType.PhoneLink]: PhoneLinkComponent;
  [ComponentType.PlainText]: PlainTextComponent;
  [ComponentType.Popover]: PopoverComponent;
  [ComponentType.ProvideProps]: ProvidePropsComponent;
  [ComponentType.RadioGroupInput]: RadioGroupInputComponent;
  [ComponentType.RadioGroupInputButton]: RadioGroupInputButtonComponent;
  [ComponentType.RequestPasswordReset]: ComponentSpec<ComponentType.RequestPasswordReset>;
  [ComponentType.ResetPassword]: ResetPasswordComponent;
  [ComponentType.Resource]: ResourceComponent;
  [ComponentType.Review]: ReviewComponent;
  [ComponentType.ScoreMyCall]: ScoreMyCallComponent;
  [ComponentType.SetUpSiteGuard]: SetUpSiteGuardComponent;
  [ComponentType.SignInGuard]: ComponentSpec<ComponentType.SignInGuard>;
  [ComponentType.SiteLandingPages]: SiteLandingPagesComponent;
  [ComponentType.SiteLogo]: SiteLogoComponent;
  [ComponentType.SiteName]: ComponentSpec<ComponentType.SiteName>;
  [ComponentType.SitePrivacyPolicy]: ComponentSpec<ComponentType.SitePrivacyPolicy>;
  [ComponentType.SiteTermsOfUse]: ComponentSpec<ComponentType.SiteTermsOfUse>;
  [ComponentType.Stage]: StageComponent;
  [ComponentType.StageBody]: StageBodyComponent;
  [ComponentType.StageFileInput]: StageFileInputComponent;
  [ComponentType.StageName]: ComponentSpec<ComponentType.StageName>;
  [ComponentType.SubscriptionTagGuard]: SubscriptionTagGuardComponent;
  [ComponentType.Text]: TextComponent;
  [ComponentType.TextInput]: TextInputComponent;
  [ComponentType.TextareaInput]: TextareaInputComponent;
  [ComponentType.Typeform]: TypeformComponent;
  [ComponentType.UserProfile]: ComponentSpec<ComponentType.UserProfile>;
  [ComponentType.VerifiedEmailAddressGuard]: VerifiedEmailAddressGuardComponent;
  [ComponentType.VerifyEmailAddress]: ComponentSpec<ComponentType.VerifyEmailAddress>;
  [ComponentType.Video]: VideoComponent;
  [ComponentType.ViewPlanStagesButton]: ViewPlanStagesButtonComponent;
  [ComponentType.WidthContainer]: WidthContainerComponent;
};

export type Component = Components[ComponentType];

export type AppPageTilesComponent = ComponentSpec<ComponentType.AppPageTiles> & {
  pathPrefix?: string | null;
};

export type OrganizationBillingComponent = ComponentSpec<ComponentType.OrganizationBilling>;

export type BodyContainerComponent = Omit<WidthContainerComponent, 'type'> & {
  type: ComponentType.BodyContainer;
};

export type CompensationCalculatorComponent = ComponentSpec<ComponentType.CompensationCalculator>;

export type CurrentYearComponent = ComponentSpec<ComponentType.CurrentYear>;

export type CustomizeOrganizationSiteComponent =
  ComponentSpec<ComponentType.CustomizeOrganizationSite>;

export type DataInputTableComponent = ComponentSpec<ComponentType.DataInputTable> & {
  readonly?: boolean | null;
};

export type MarketingCalculatorComponent = ComponentSpec<ComponentType.MarketingCalculator>;

export type OrderPageCheckoutOption = {
  subscriptionPlanId: string;
  // If not set, the condition is assumed to be satisfied.
  condition?: BooleanExpression | null;
};

export enum OrderFormPromoCodeType {
  Stripe = 'Stripe',
  Custom = 'Custom',
}

export type OrderPageComponent = ComponentSpec<ComponentType.OrderPage> & {
  enablePromoCode?: OrderFormPromoCodeType | null;
  checkoutOptions?: OrderPageCheckoutOption[] | null;
};

export type OrganizationAgencyAddressLine1Component =
  ComponentSpec<ComponentType.OrganizationAgencyAddressLine1>;

export type OrganizationAgencyAddressLine2Component =
  ComponentSpec<ComponentType.OrganizationAgencyAddressLine2>;

export type OrganizationAgencyAddressCityComponent =
  ComponentSpec<ComponentType.OrganizationAgencyAddressCity>;

export type OrganizationAgencyAddressZipComponent =
  ComponentSpec<ComponentType.OrganizationAgencyAddressZip>;

export type OrganizationAgentFirstNameComponent =
  ComponentSpec<ComponentType.OrganizationAgentFirstName>;

export type OrganizationAgentFullNameComponent =
  ComponentSpec<ComponentType.OrganizationAgentFullName>;

export type OrganizationAgentLastNameComponent =
  ComponentSpec<ComponentType.OrganizationAgentLastName>;

export type ScoreMyCallComponent = ComponentSpec<ComponentType.ScoreMyCall>;

export type SetUpSiteGuardComponent = ComponentSpec<ComponentType.SetUpSiteGuard>;

export type SiteLandingPagesComponent = ComponentSpec<ComponentType.SiteLandingPages>;

// An "img" element.
// When the site name is rendered as fallback, it is rendered inside a div.
export type SiteLogoComponent = ComponentSpec<ComponentType.SiteLogo> & {
  alt?: string | null;
  // Deprecated, replace by fallbackSiteName.
  defaultAgencyName?: boolean;
  // Defaults to false.
  fallbackSiteName?: boolean;
};

export type StageComponent = ComponentSpec<ComponentType.Stage> & {
  planId?: string | null;
  enableWrappedStageBody?: boolean | null;
};

// This component does not add any HTML markup. So styling and classes are ignored.
export type StageBodyComponent = ComponentSpec<ComponentType.StageBody>;

export enum SubscriptionTagGuardAccessDeniedMode {
  CustomMessage = 'CustomMessage',
  DefaultMessage = 'DefaultMessage',
  Empty = 'Empty',
}

// Guard the rendering of children with a subscription tag that the user must have. Children components without
// a "slot" property are considered to be the guarded content as if they have slot = AccessAllowed. In other words,
// the default slot key for children is AccessAllowed.
export type SubscriptionTagGuardComponent = ComponentSpec<ComponentType.SubscriptionTagGuard> & {
  tag?: string | null;
  // The default accessDeniedMode is DefaultMessage. A custom message is defined through children
  // with an AccessDenied slot key.
  accessDeniedMode?: SubscriptionTagGuardAccessDeniedMode | null;
};

export enum TextInputComponentFormat {
  None = 'None',
  Email = 'Email',
  Phone = 'Phone',
  // TODO: Implement validation for URLs.
  Url = 'Url',
}

export type TextInputComponent = ComponentSpec<ComponentType.TextInput> & {
  placeholder?: string | null;
  format?: TextInputComponentFormat | null;
};

export type TextareaInputComponent = ComponentSpec<ComponentType.TextareaInput> & {
  placeholder?: string | null;
  maxLength?: number | null;
};

export type DateInputComponentDateSpec =
  | { type: 'today' }
  // For 'date' the value is a date in the ISO 8601 format.
  | { type: 'date'; value: string };

export enum DateInputComponentStyle {
  Calendar = 'Calendar',
  ThreeFields = '3Fields',
}

// A DateInputComponent stores values in the YYYY-MM-DD format.
export type DateInputComponent = ComponentSpec<ComponentType.DateInput> & {
  placeholder?: string | null;
  clearable?: boolean | null;
  style?: DateInputComponentStyle | null;
  minimumDate?: DateInputComponentDateSpec | null;
  maximumDate?: DateInputComponentDateSpec | null;
  monthPlaceholder?: string | null;
  dayPlaceholder?: string | null;
  yearPlaceholder?: string | null;
};

export type DropdownOption = {
  key: number;
  // value is displayed and is how an option is serialized.
  value: string;
};

export type DropdownInputComponent = ComponentSpec<ComponentType.DropdownInput> & {
  // Clearable is ignored if the input is required. When the input is not required, it may or may not be clearable.
  // The default is false.
  clearable?: boolean | null;
  placeholder?: string | null;
  options?: DropdownOption[] | null;
};

export enum NumberInputFormat {
  Number = 'Number',
  Currency = 'Currency',
  Integer = 'Integer',
}

export type NumberInputComponent = ComponentSpec<ComponentType.NumberInput> & {
  maxDecimalDigits?: number | null;
  placeholder?: string | null;
  format?: NumberInputFormat | null;
};

export type BooleanInputComponent = ComponentSpec<ComponentType.BooleanInput>;

export type CompletableVideoInputComponent = ComponentSpec<ComponentType.CompletableVideoInput> & {
  src?: string | null;
  showHeader?: boolean | null;
  // By default, collapseCompleted is false.
  collapseCompleted?: boolean | null;
  // Default initialCollapsed is false.
  initiallyCollapsed?: boolean | null;
};

export type ConditionalLogicComponent = ComponentSpec<ComponentType.ConditionalLogic> & {
  condition?: BooleanExpression | null;
};

export type CourseComponent = ComponentSpec<ComponentType.Course> & {
  planId?: string | null;
  enableNotes?: boolean | null;
  emptyNotesMessage?: string | null;
};

export type CourseSubSectionComponent = ComponentSpec<ComponentType.CourseSubSection> & {
  heading?: string | null;
};

export type MultipleChoiceInputComponent = ComponentSpec<ComponentType.MultipleChoiceInput> & {
  question?: string | null;
  choices?: string[] | null;
  answerIndex?: number | null;
};

export type MultiSelectInputComponent = ComponentSpec<ComponentType.MultiSelectInput> & {
  question?: string | null;
  choices?: string[] | null;
  answerIndices?: number[] | null;
};

export enum FileTypeCategory {
  Audio = 'Audio',
  Image = 'Image',
  Video = 'Video',
}

export type StageFileInputComponent = ComponentSpec<ComponentType.StageFileInput> & {
  allowedTypes?: FileTypeCategory[] | null;
};

export enum AddressStateTransform {
  Full = 'Full',
  None = 'None',
}

export type OrganizationAgencyAddressStateComponent =
  ComponentSpec<ComponentType.OrganizationAgencyAddressState> & {
    transform?: AddressStateTransform | null;
  };

export enum PhoneNumberFormat {
  None = 'None',
  Verbose = 'Verbose',
  Dashes = 'Dashes',
  Spaces = 'Spaces',
}

export type OrganizationAgencyPrimaryPhoneComponent =
  ComponentSpec<ComponentType.OrganizationAgencyPrimaryPhone> & {
    format?: PhoneNumberFormat | null;
  };

export type OrganizationLogoComponent = ComponentSpec<ComponentType.OrganizationLogo> &
  Pick<SiteLogoComponent, 'alt' | 'defaultAgencyName' | 'fallbackSiteName'>;

export type OrgTeamComponent = ComponentSpec<ComponentType.OrganizationTeam> & {
  showUserTrainingProgress?: boolean | null;
  userTrainingProgressContentPlanIds?: string[] | null;
};

export type BoxComponent = ComponentSpec<ComponentType.Box> & {
  tag?: string | null;
  columnWidths?: TwoColumnWidths | ThreeColumnWidths;
  smColumnWidths?: TwoColumnWidths | ThreeColumnWidths;
  mdColumnWidths?: TwoColumnWidths | ThreeColumnWidths;
  lgColumnWidths?: TwoColumnWidths | ThreeColumnWidths;
};

export type ButtonOnClick =
  | { type: 'doNothing' }
  | { type: 'submitForm' }
  | { type: 'resetForm' }
  | { type: 'navigate'; url: string; isExternal?: boolean; openInNewTab?: boolean };

type TooltipOptions = {
  disabled?: boolean | undefined;
  event?: 'hover' | 'focus' | 'both' | undefined;
  showDelay?: number | undefined;
  hideDelay?: number | undefined;
};

export type ButtonComponent = ComponentSpec<ComponentType.Button> & {
  // text is deprecated. Use label instead. TODO: Or, better, children?
  text?: string | null;
  // If onClick is null or undefined, nothing happens on click.
  onClick?: ButtonOnClick | null;
  icon?: string | null;
  iconPosition?: ButtonIconPosition | null;
  tooltip?: string | null;
  tooltipOptions?: TooltipOptions | null;
};

export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}

export type CalendlyEmbedUrlSource = DataSourceSpec<{
  url?: string | null;
}>;

export type CalendlyEmbedUrlQueryParameterValueSource = DataSourceSpec<{
  value?: string | null;
}>;

export type CalendlyEmbedQueryParameter = {
  id: string;
  parameter: string;
  value: CalendlyEmbedUrlQueryParameterValueSource;
};

export type CalendlyEmbedComponent = ComponentSpec<ComponentType.CalendlyEmbed> & {
  url?: CalendlyEmbedUrlSource | null;
  queryParameters?: CalendlyEmbedQueryParameter[] | null;
};

export type CollapsiblePanelComponent = ComponentSpec<ComponentType.CollapsiblePanel> & {
  header?: string | null;
  // TODO: Implement this option.
  expandedByDefault?: boolean;
};

export type DesignComponent = ComponentSpec<ComponentType.Design> & {
  // Also deprecate in favor of "componentId"?
  customComponentId?: string | null;
  // Deprecated
  designId?: string | null;
};

export type DocumentComponent = ComponentSpec<ComponentType.Document> & {
  documentId?: string | null;
  // Deprecated
  resourceId?: string | null;
};

export type FormComponent = ComponentSpec<ComponentType.Form> & {
  formId?: string;
};

export type FormConfirmationMessageComponent = ComponentSpec<ComponentType.FormConfirmationMessage>;

export type FormStepComponent = ComponentSpec<ComponentType.FormStep>;

export type HTMLComponent = ComponentSpec<ComponentType.HTML> & {
  html?: string | null;
};

export type MapFocus =
  // Note that 'OrganizationPlace' is deprecated.
  | { type: 'OrganizationPlace' | 'SiteGooglePlace' }
  | { type: 'CustomGooglePlace'; placeId: string | null };

export type MapComponent = ComponentSpec<ComponentType.Map> & {
  focus?: MapFocus;
};

export type MoveUserPlanComponent = ComponentSpec<ComponentType.MoveUserPlan> & {
  previousButtonLabel?: string | null;
  nextButtonLabel?: string | null;
};

export type MultiOrgViewComponent = ComponentSpec<ComponentType.MultiOrgView> & {
  showUserTrainingProgress?: boolean | null;
  userTrainingProgressContentPlanIds?: string[] | null;
};

export type IconComponent = ComponentSpec<ComponentType.Icon> & {
  icon?: string | null;
};

export type ImageSource =
  | DataSourceSpec<{
      url: string;
    }>
  | { type: 'URL'; url: string } // Legacy
  | { type: 'OrganizationLogoUrl' }
  | { type: 'OrganizationAgentPhotoUrl' };

export type ImageComponent = ComponentSpec<ComponentType.Image> & {
  alt?: string | null;
  src?: ImageSource | null;
};

export enum PaddingAmount {
  StandardPageBody = 'StandardPageBody',
  Narrow = 'Narrow',
  Medium = 'Medium',
  Wide = 'Wide',
  XWide = 'XWide',
}

export type PaddingSource = DataSourceSpec<{
  padding?: PaddingAmount | null;
}>;

export type PaddingComponent = ComponentSpec<ComponentType.Padding> & {
  padding?: PaddingSource | null;
};

export type PhoneNumber =
  | DataSourceSpec<{
      phoneNumber?: string | null;
    }>
  // Legacy
  | { type: 'custom'; text: string }
  | { type: 'agencyPrimaryPhone' };

export type PhoneLinkComponent = ComponentSpec<ComponentType.PhoneLink> & {
  phoneNumber?: PhoneNumber;
};

export type LinkComponent = ComponentSpec<ComponentType.Link> & {
  // Must be an absolute path without a host.
  to?: string;
  // isExternal defaults to false.
  isExternal?: boolean;
  openInNewTab?: boolean;
};

export type PlainTextSource =
  | DataSourceSpec<{
      text?: string | null;
    }>
  | PlainTextSourcePageTitle;

type PlainTextSourcePageTitle = {
  type: 'PageTitle';
};

export type PlainTextComponent = ComponentSpec<ComponentType.PlainText> & {
  tag?: string | null;
  // The string value is legacy.
  text?: string | PlainTextSource | null;
};

export enum MixerEventType {
  Click = 'Click',
  InputChange = 'InputChange',
}

export type Trigger = {
  // id is used for internal purposes (e.g., rendering).
  id: string;
  type: MixerEventType;
  // If not set, the condition is assumed to be satisfied.
  condition?: BooleanExpression | null;
};

export type PopoverComponent = ComponentSpec<ComponentType.Popover> & {
  showTriggers?: Trigger[] | null;
  hideTriggers?: Trigger[] | null;
};

export type ProvidePropValueSource =
  | DataSourceSpecDirect<ProvidePropValueSourceDirect>
  | ProvidePropValueSourceQueryParameter;

export enum ProvidePropValueSourceDirectType {
  Number = 'Number',
  String = 'String',
}

export type ProvidePropValueSourceDirectValue =
  | { type: ProvidePropValueSourceDirectType.String; value: string | null }
  | { type: ProvidePropValueSourceDirectType.Number; value: number | null };

export type ProvidePropValueSourceDirect = {
  value: ProvidePropValueSourceDirectValue | null;
};

export type ProvidePropValueSourceQueryParameter = {
  type: 'QueryParameter';
  parameter: string;
};

export type ProvideProp = {
  id: string;
  name: string;
  source: ProvidePropValueSource;
};

export type ProvidePropsComponent = ComponentSpec<ComponentType.ProvideProps> & {
  props?: ProvideProp[] | null;
};

export type RadioGroupInputComponent = ComponentSpec<ComponentType.RadioGroupInput>;

// A RadioGroupInputButton is rendered only if value is not null and not undefined.
export type RadioGroupInputButtonComponent = ComponentSpec<ComponentType.RadioGroupInputButton>;

export type ResetPasswordComponent = ComponentSpec<ComponentType.ResetPassword> & {
  mode?: 'create' | 'reset' | null;
};

export type ResourceComponent = ComponentSpec<ComponentType.Resource> &
  Pick<DocumentComponent, 'documentId' | 'resourceId'>;

export type ReviewComponent = ComponentSpec<ComponentType.Review> & {
  googlePlaceId?: string | null;
};

export enum TwoColumnWidths {
  // 1/2 1/2
  OneHalfOneHalf = 'OneHalfOneHalf',
  // 1/4 3/4
  OneQuarterThreeQuarters = 'OneQuarterThreeQuarters',
  // 1/3 2/3
  OneThirdTwoThirds = 'OneThirdTwoThirds',
  // 3/4 1/4
  ThreeQuartersOneQuarter = 'ThreeQuartersOneQuarter',
  // 2/3 1/3
  TwoThirdsOneThird = 'TwoThirdsOneThird',
  // 100% 100%
  FullFull = 'FullFull',
}

export enum ThreeColumnWidths {
  // 1/2 1/4 1/4
  OneHalfOneQuarterOneQuarter = 'OneHalfOneQuarterOneQuarter',
  // 1/4 1/2 1/4
  OneQuarterOneHalfOneQuarter = 'OneQuarterOneHalfOneQuarter',
  // 1/4 1/4 1/2
  OneQuarterOneQuarterOneHalf = 'OneQuarterOneQuarterOneHalf',
  // 1/3 1/3 1/3
  OneThirdOneThirdOneThird = 'OneThirdOneThirdOneThird',
  // 100% 100% 100%
  FullFullFull = 'FullFullFull',
}

export type TextComponent = ComponentSpec<ComponentType.Text> & {
  content?: Delta | null;
};

export type TypeformComponent = ComponentSpec<ComponentType.Typeform> & {
  formId?: string | null;
};

export type VerifiedEmailAddressGuardComponent =
  ComponentSpec<ComponentType.VerifiedEmailAddressGuard>;

export type VideoComponent = ComponentSpec<ComponentType.Video> & {
  src?: string | null;
};

export type ViewPlanStagesButtonComponent = ComponentSpec<ComponentType.ViewPlanStagesButton> & {
  dialogHeader?: string | null;
  stageNameTableHeader?: string | null;
};

export enum Width {
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  XXLarge = 'XXLarge',
}

export type WidthContainerComponent = ComponentSpec<ComponentType.WidthContainer> & {
  // Default width is Large.
  width?: Width | null;
};

export type NonEmptyChildren = {
  children: [Component, ...Component[]];
};
