export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ApplyCustomPromoCodeResult: ['ApplyCustomPromoCodeSuccessResult', 'GenericInternalError'],
    ArchiveComponentResult: ['ArchiveComponentSuccessResult', 'GenericUserError'],
    ContentPlan: ['Course', 'Sequence'],
    CreateComponentResult: ['CreateComponentSuccessResult', 'GenericUserError'],
    CreateCourseResult: ['CreateCourseSuccessResult', 'GenericUserError'],
    CreateLayoutResult: ['CreateLayoutSuccessResult', 'GenericUserError'],
    CreatePagePurposeResult: ['CreatePagePurposeSuccessResult', 'GenericUserError'],
    CreatePagesFromTemplatePurposeResult: [
      'CreatePagesFromTemplatePurposeSuccessResult',
      'GenericUserError',
    ],
    CreateProductResult: ['CreateProductSuccessResult', 'GenericUserError'],
    CreateSequenceResult: ['CreateSequenceSuccessResult', 'GenericUserError'],
    DuplicateComponentResult: [
      'DuplicateComponentSuccessResult',
      'GenericInternalError',
      'GenericUserError',
    ],
    DuplicateCourseResult: ['DuplicateCourseSuccessResult', 'GenericUserError'],
    DuplicateSequenceResult: ['DuplicateSequenceSuccessResult', 'GenericUserError'],
    EditComponentResult: ['EditComponentSuccessResult', 'GenericUserError'],
    EditCourseResult: ['EditCourseSuccessResult', 'GenericUserError'],
    EditCustomTableResult: ['EditCustomTableSuccessResult', 'GenericUserError'],
    EditFormTemplateEmailTemplateResult: [
      'EditFormTemplateEmailTemplateSuccessResult',
      'GenericUserError',
    ],
    EditFormTemplateFormattedDataTemplateResult: [
      'EditFormTemplateFormattedDataTemplateSuccessResult',
      'GenericUserError',
    ],
    EditLayoutResult: ['EditLayoutSuccessResult', 'GenericUserError'],
    EditLessonOutput: ['EditLessonSuccessResult', 'GenericUserError'],
    EditOrgPrimaryUserResult: ['EditOrgPrimaryUserSuccessResult', 'GenericUserError'],
    EditPagePurposeResult: ['EditPagePurposeSuccessResult', 'GenericUserError'],
    EditPageTemplateResult: ['EditPageTemplateSuccessResult', 'GenericUserError'],
    EditPlatformResult: ['EditPlatformSuccessResult', 'GenericUserError'],
    EditProductResult: ['EditProductSuccessResult', 'GenericUserError'],
    EditSequenceResult: ['EditSequenceSuccessResult', 'GenericUserError'],
    EditUserResult: ['EditUserSuccessResult', 'GenericUserError'],
    FindOrCreateStripeSubscriptionResult: [
      'FindOrCreateStripeSubscriptionActiveResult',
      'FindOrCreateStripeSubscriptionErrorResult',
      'FindOrCreateStripeSubscriptionIncompleteResult',
      'FindOrCreateStripeSubscriptionUpdatedResult',
    ],
    InternalError: ['GenericInternalError'],
    ListResult: [
      'ActiveCampaignApiTagsList',
      'ApiTokensList',
      'ComponentsList',
      'ContentPlansList',
      'CourseVersionsList',
      'CoursesList',
      'CustomFieldVersionsList',
      'CustomFieldsList',
      'CustomTableVersionFieldsList',
      'CustomTableVersionsList',
      'CustomTablesList',
      'DocumentsList',
      'ExtensionsList',
      'FormEntriesList',
      'LayoutsList',
      'LessonsList',
      'OrganizationsList',
      'PagePurposesList',
      'PageVersionsList',
      'PagesList',
      'ProductItemPicklist',
      'ProductsList',
      'PromoCodePicklist',
      'ReviewsList',
      'SequencesList',
      'SitesList',
      'SubscriptionsList',
      'UsersList',
    ],
    MoveUserToOrgResult: ['GenericUserError', 'MoveUserToOrgSuccessResult'],
    MutationResult: ['CreateApiTokenResult', 'InvalidateApiTokenResult'],
    PreviewStripeUpcomingInvoiceResult: [
      'PreviewStripeUpcomingInvoiceErrorResult',
      'PreviewStripeUpcomingInvoiceSuccessResult',
    ],
    SplitUserToNewOrgResult: ['GenericUserError', 'SplitUserToNewOrgSuccessResult'],
    SyncPageTemplateToPagesResult: ['GenericUserError', 'SyncPageTemplateToPagesSuccessResult'],
    UserError: ['GenericUserError'],
  },
};
export default result;
